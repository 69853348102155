import React, { useState } from "react"
import { Link, graphql } from "gatsby"
import parse from "html-react-parser"

import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"

import '../scss/blog.scss'

import BlogHero from "../components/template-parts/blog-hero"
import ContentBar from "../components/template-parts/content-bar"
import BlogCategories from "../components/template-parts/blog-categories"


const BlogIndex = ({ data, pageContext: { nextPagePath, previousPagePath } }) => {

	const posts = data.allWpPost.nodes
	const categories = data.allWpCategory.categories
	const podcast = data?.wp?.podcast?.podcastData
	const blog = data?.wp?.blog?.blog

	console.log(categories)

	const [filteredPosts, setFiteredPosts] = useState(posts)

	const changeCategory = (slug) => {
		if (slug) {
			const updated = posts.filter(post => post.categories.nodes.some(cat => cat.slug === slug))
			setFiteredPosts(updated)
		} else {
			setFiteredPosts(posts)
		}
	}

	return (
		<Layout isHomePage>
			<Seo title="All posts" />
			<BlogHero podcast={podcast} blog={blog} />
			<ContentBar backgroundColor={'#003366'} color={'#ffffff'} centered={true}>
				{parse(blog.copy)}
			</ContentBar>
			<section className="posts">
				<div className="container">
					<BlogCategories categories={categories} blog={blog} toggleCat={(slug) => changeCategory(slug)} />
					<div className="posts-grid">
						{filteredPosts && filteredPosts.length ? filteredPosts.map(post => {
							const image = post && post.featuredImage && post.featuredImage.image && post.featuredImage.image.localFile && getImage(post.featuredImage.image.localFile)

							console.log(post?.blogPost?.outsideLink?.url, 'post')
							return (
								<article className="post-list-item" itemScope itemType="http://schema.org/Article" key={post.id}>
									<div className="image">
										{image && !post?.blogPost?.outsideLink?.url && (
											<Link to={post.uri} itemProp="url">
												<div className="overlay"></div>
												<GatsbyImage style={{gridArea: "1/1"}} layout={'constrained'} image={image} alt={post.title} />
											</Link>
										)}
										{image && post?.blogPost?.outsideLink?.url && (
											<a href={post?.blogPost?.outsideLink?.url} target="_blank">
												<div className="overlay"></div>
												<GatsbyImage style={{gridArea: "1/1"}} layout={'constrained'} image={image} alt={post.title} />
											</a>
										)}
									</div>
									<div className="content">
										<h2>
											{!post?.blogPost?.outsideLink?.url && (
												<Link to={post.uri} itemProp="url">
													<span itemProp="headline">{parse(post.title)}</span>
												</Link>
											)}
											{post?.blogPost?.outsideLink?.url && (
												<a href={post?.blogPost?.outsideLink?.url} target="_blank">
													<span itemProp="headline">{parse(post.title)}</span>
												</a>
											)}
										</h2>
										<small>{post.author.node.name} | {post.date}</small>
											{!post?.blogPost?.outsideLink?.url && (
												<section itemProp="description">{parse(post.excerpt)}</section>
											)}
									</div>
								</article>
							)
						}) : (
							<p>
								No posts found.
							</p>
						)}
					</div>
				</div>
			</section>
			<section className="pagination">
				<div className="container">
					{nextPagePath ? <Link to={nextPagePath} className="older"><span>&laquo;</span> Older</Link> : <span />}
					{previousPagePath ? <Link to={previousPagePath} className="newer">Newer <span>&raquo;</span></Link> : <span />}
				</div>
			</section>
		</Layout>
	)
}

export default BlogIndex

export const pageQuery = graphql`
  query WordPressPostArchive($offset: Int!, $postsPerPage: Int!) {
	allWpPost(
	  sort: { fields: [date], order: DESC }
	  limit: $postsPerPage
	  skip: $offset
	) {
	  nodes {
		id
		excerpt
		uri
		date(formatString: "MMMM DD, YYYY")
		title
		excerpt
		categories {
			nodes {
				slug
			}
		}
		author {
			node {
				name
			}
		}
		featuredImage {
			image: node {
				localFile {
					childImageSharp {
						gatsbyImageData(
							layout: CONSTRAINED
							width: 670
							height: 443
							placeholder: BLURRED
							formats: [AUTO]
						)
					}
				}
			}
		}
		blogPost {
			outsideLink {
	        url
	      }
		  }
	  }
	}
	allWpCategory {
		categories: nodes {
			slug
			name
			databaseId
			count
		}
	}
	# Podcast options
	wp {
		podcast {
			podcastData {
				title
				description
				spotifyLink {
					url
				}
				applePodcastsLink {
					url
				}
				image {
					localFile {
						childImageSharp {
							gatsbyImageData(
								quality: 100
								placeholder: BLURRED
								layout: FULL_WIDTH
								aspectRatio: 1
							)
						}
					}
				}
			}
		}
		blog {
			blog {
				copy
				blogTitle
				blogContent
				blogSubTitle
			}
		}
	}
  }
`
