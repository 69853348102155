import React from 'react'
import parse from "html-react-parser"

import PlayButton from '../../svg/play-button.svg'

import '../../scss/blog-hero.scss'
import { GatsbyImage } from 'gatsby-plugin-image'

const BlogHero = ({ podcast, blog }) => {

    return (
        <section className="blog-hero" style={{minHeight: '800px'}}>
            <div className="overlay">
                <div className="row">
                    <div className="col col-2-3">
                        <div className="podcast-container">
                            <div className="image">
                                {podcast?.image?.localFile?.childImageSharp?.gatsbyImageData && <GatsbyImage image={podcast.image.localFile.childImageSharp.gatsbyImageData} alt="Podcast Image" />}
                            </div>
                            <div className="content">
                                <h1>
                                    {podcast?.title}
                                </h1>
                                {podcast?.description && parse(podcast.description)}
                                <div className="play-button">
                                    {podcast?.spotifyLink?.url && (
                                        <a href={podcast.spotifyLink.url} target="blank" rel="noopener">
                                            <PlayButton />
                                        </a>
                                    )}
                                </div>
                                <div className="podcasts">
                                    {podcast?.spotifyLink?.url && (
                                        <a href={podcast.spotifyLink.url} className="podcast" target="blank" rel="noopener">
                                            <img src="/images/spotify.svg" alt="Spotify link to podcast" height="40" />
                                        </a>
                                    )}
                                    {podcast?.applePodcastsLink?.url && (
                                        <a href={podcast.applePodcastsLink.url} className="podcast" target="blank" rel="noopener">
                                            <img src="/images/apple-podcast.svg" alt="Apple link to podcast" />
                                        </a>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col col-1-3">
                        <div className="blog-container">
                            <h2>{blog.blogTitle}</h2>
                            {blog?.blogContent && parse(blog.blogContent)}
                            <h3>{blog.blogSubTitle}</h3>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BlogHero
