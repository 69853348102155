import React, { Fragment, useState } from 'react'
import parse from "html-react-parser"

const BlogCategories = ({ categories, blog, toggleCat }) => {

    const [activeCat, setActiveCat] = useState('')

    const handleToggle = (slug) => {
        setActiveCat(activeCat === slug ? '' : slug)
        toggleCat(activeCat === slug ? '' : slug)
    }

    return (
        <Fragment>
{/*            <div className="category-header">
                <h2>{blog?.blogTitle}</h2>
                {parse(blog?.blogContent)}
            </div>*/}
            <div className="categories">
                
                {categories && categories.length && categories.map(category => {
                    return category.databaseId !== 1 && category.count && (
                        <button 
                        key={category.databaseId}
                        onClick={() => handleToggle(category.slug)}
                        className={activeCat === category.slug ? 'active' : ''}
                        >
                            {category.name}
                        </button>
                    )
                })}
            </div>
        </Fragment>
    )
}

export default BlogCategories
